<template>
  <div class="page">
    <div class="pageFucView">
      <div class="pageFucView_left">
        <el-button class="button" type="primary" icon="el-icon-plus" @click="handleEdit" size="small">添加</el-button>
      </div>
      <div class="pageFucView_right">
      </div>
    </div>
    <el-table
        :data="list">
      <el-table-column
          align="center"
          prop="name"
          label="标题">
      </el-table-column>
      <el-table-column
          align="center"
          label="链接">
        <template slot-scope="scope">
          <el-link :href="scope.row.url" type="primary" target="_blank" >{{scope.row.url}}</el-link>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          label="是否启用"
          prop="state"
          show-overflow-tooltip
      >
        <template #default="{ row }">
          <el-tooltip
              :content="row.state == 1 ? '点击禁用' : '点击启用'"
              :enterable="false"
              placement="top"
          >
            <el-switch
                v-model="row.state"
                active-value="1"
                inactive-value="0"
                :value="true"
                @change="changeStatus(row.id, row.state)"
            />
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" @click="handleDelete(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Edit ref="Edit" @fetch-data="getData" />
  </div>
</template>
<script>
import Edit from './components/Edit'
import {apiGetQuickLinkList,apiDelQuickLink} from "@/request/api";

export default {
  filters: {
    formatDate(time) {
      var date = new Date(time * 1000);
      return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    }
  },
  components: { Edit },
  data() {
    return {
      list: [],
    }
  },
  created() {
    this.getData()
  },
  methods: {
    handleEdit(row) {
      if (row.id) {
        this.$refs['Edit'].showEdit(row)
      } else {
        this.$refs['Edit'].showEdit()
      }
    },
    getData(){
      apiGetQuickLinkList().then(res=>{
        if(res.code == 200){
          this.list=res.data
        }
      })
    },
    handleDelete(id){
      this.$confirm('是否删除该快捷链接?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        apiDelQuickLink({id:id}).then(res=>{
          if(res.code == 200){
            this.getData()
          }
        })
      }).catch(() => {});
    }
  }
}

</script>
<style>
.page {
  background-color: #FFF;
  padding: 20px;
  margin: 20px;
  margin-bottom: 80px;
}
.pageView {
  display: flex;
  justify-content: flex-end;
}
.pageFucView {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
